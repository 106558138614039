import React from "react"
import QRCodeChina from "../images/icons/QR_Code_China.jpeg"

export default () => (
  <div className="menu-button -blue-gradient shop-now-toggle">
    <div className="shop-now__background"></div>
    <div className="js-shop-now" onClick={toggleShopNow}></div>
    <span className="shop-now-toggle__button-text">现在购买</span>

    <div className="shop-now__inner">
      <p>
        <i className="fas fa-map-marked-alt"></i>
        <br />
        选择运送目的地
      </p>
      <span className="help-text">滚动下拉查找您的收货地点</span>

      <div className="shipping-destinations">
        <div className="shipping-destinations__item">
          <input type="radio" id="destChina" name="destinations" value={QRCodeChina} defaultChecked onChange={showQRCode} />
          <label htmlFor="destChina">中国大陆</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destUS" name="destinations" value="http://bit.ly/35gYLTM" onChange={updateCta} />
          <label htmlFor="destUS">美国</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destCanada" name="destinations" value="http://bit.ly/2qqFpvH" onChange={updateCta} />
          <label htmlFor="destCanada">加拿大</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destHongKong" name="destinations" value="http://bit.ly/2M9ESXw" onChange={updateCta} />
          <label htmlFor="destHongKong">香港特别行政区</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destTaiwan" name="destinations" value="http://bit.ly/2MAkeP7" onChange={updateCta} />
          <label htmlFor="destTaiwan">台湾地区</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destSingapore" name="destinations" value="http://bit.ly/2oFZE7Y" onChange={updateCta} />
          <label htmlFor="destSingapore">新加坡</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destMalaysia" name="destinations" value="http://bit.ly/2IJuBz0" onChange={updateCta} />
          <label htmlFor="destMalaysia">马来西亚</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destIndonesia" name="destinations" value="http://bit.ly/32bCZz5" onChange={updateCta} />
          <label htmlFor="destIndonesia">印度尼西亚</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destThailand" name="destinations" value="http://bit.ly/2B54WN2" onChange={updateCta} />
          <label htmlFor="destThailand">泰国</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destIndia" name="destinations" value="http://bit.ly/2rmm7YV" onChange={updateCta} />
          <label htmlFor="destIndia">印度</label>
        </div>
      </div>

      <span className="help-text -mainland-china-text">机场专享优惠于结账时适用*</span>
      <span className="help-text -other-countries">
        机场专享优惠于结账时适用*
        <br />
        输入优惠码: SCHIPHOL5
      </span>

      <a href="http://bit.ly/35gYLTM" target="_blank" rel="noopener noreferrer" className="cta shop-now-cta -gold-gradient">下一页</a>
      <span className="help-text -continue-text">
        您将被重定向到可能非适用于本国家或地区居民的网站。
        <br />
        <br />
        <span>*优惠仅限于结账时使用于部分产品。 大多数国家的优惠不包括婴儿配方奶粉和医疗食品</span>
      </span>

      <div className="china-qr-code">
        <span>请使用微信扫一扫至线上商店</span>
        <img src={QRCodeChina} alt="" />
        <span>*优惠仅限于结账时使用于部分产品。 大多数国家的优惠不包括婴儿配方奶粉和医疗食品</span>
      </div>
    </div>
  </div>
)

function toggleShopNow(e) {
  document.getElementsByClassName('shop-now-toggle')[0].classList.toggle('-open')
  document.body.classList.toggle('-shop-now-active')
}

function updateCta(event) {
  let el = event.target
  document.getElementsByClassName('shop-now-cta')[0].setAttribute('href', el.value)
  document.querySelector('.china-qr-code').style.display = "none"  
  document.querySelector('.help-text.-mainland-china-text').style.display = "none"  
  document.querySelector('.help-text.-continue-text').style.display = "block" 
  document.querySelector('.help-text.-other-countries').style.display = "block" 
  document.getElementsByClassName('shop-now-cta')[0].style.display = "inline-block"
} 

function showQRCode() {
  document.getElementsByClassName('shop-now-cta')[0].style.display = "none"
  document.querySelector('.china-qr-code').style.display = "block"
  document.querySelector('.help-text.-mainland-china-text').style.display = "block"  
  document.querySelector('.help-text.-continue-text').style.display = "none"  
  document.querySelector('.help-text.-other-countries').style.display = "none"  
}