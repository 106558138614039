import React from "react"
import QRCodeCanada from "../images/icons/QR_Code_Canada.png"
import QRCodeChina from "../images/icons/QR_Code_China.jpeg"
import QRCodeHK from "../images/icons/QR_Code_HK.png"
import QRCodeIndonesia from "../images/icons/QR_Code_Indonesia.png"
import QRCodeMalaysia from "../images/icons/QR_Code_Malaysia.png"
import QRCodeSingapore from "../images/icons/QR_Code_Singapore.png"
import QRCodeTaiwan from "../images/icons/QR_Code_Taiwan.png"
import QRCodeThailand from "../images/icons/QR_Code_Thailand.png"
import QRCodeUS from "../images/icons/QR_Code_US.png"
import QRCodeIndia from "../images/icons/QR_Code_India.png"

class ShopNowTouchscreen extends React.Component {
  render() {
    return(
      <div className="menu-button -blue-gradient shop-now-toggle -touchscreen">
        <div className="shop-now__background"></div>
        <div className="js-shop-now-touchscreen" onClick={toggleShopNow}></div>
        <span className="shop-now-toggle__button-text">现在购买</span>

        <div className="shop-now__inner">
          <p>
            <i className="fas fa-map-marked-alt"></i>
            <br />
            选择运送目的地
          </p>

          <span className="help-text">滚动下拉查找您的收货地点</span>


          <div className="shipping-destinations">
            <div className="shipping-destinations__item">
              <input type="radio" id="destChinaTouchscreen" name="destinationsTouchscreen" value={QRCodeChina} defaultChecked onChange={updateCta} />
              <label htmlFor="destChinaTouchscreen">中国大陆</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destUSTouchscreen" name="destinationsTouchscreen" value={QRCodeUS} onChange={updateCta} />
              <label htmlFor="destUSTouchscreen">美国</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destCanadaTouchscreen" name="destinationsTouchscreen" value={QRCodeCanada} onChange={updateCta} />
              <label htmlFor="destCanadaTouchscreen">加拿大</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destHongKongTouchscreen" name="destinationsTouchscreen" value={QRCodeHK} onChange={updateCta} />
              <label htmlFor="destHongKongTouchscreen">香港特别行政区</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destTaiwanTouchscreen" name="destinationsTouchscreen" value={QRCodeTaiwan} onChange={updateCta} />
              <label htmlFor="destTaiwanTouchscreen">台湾地区</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destSingaporeTouchscreen" name="destinationsTouchscreen" value={QRCodeSingapore} onChange={updateCta} />
              <label htmlFor="destSingaporeTouchscreen">新加坡</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destMalaysiaTouchscreen" name="destinationsTouchscreen" value={QRCodeMalaysia} onChange={updateCta} />
              <label htmlFor="destMalaysiaTouchscreen">马来西亚</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destIndonesiaTouchscreen" name="destinationsTouchscreen" value={QRCodeIndonesia} onChange={updateCta} />
              <label htmlFor="destIndonesiaTouchscreen">印度尼西亚</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destThailandTouchscreen" name="destinationsTouchscreen" value={QRCodeThailand} onChange={updateCta} />
              <label htmlFor="destThailandTouchscreen">泰国</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destIndiaTouchscreen" name="destinationsTouchscreen" value={QRCodeIndia} onChange={updateCta} />
              <label htmlFor="destIndiaTouchscreen">印度</label>
            </div>
          </div>

          <span className="help-text">
            您将被重定向到可能非适用于本国家或地区居民的网站。
          </span>

          <span className="help-text -mainland-china-text">机场专享优惠于结账时适用*</span>
          <span className="help-text -other-countries">
            机场专享优惠于结账时适用*
            <br />
            输入优惠码: SCHIPHOL5
          </span>

          <div className="qr-code-wrapper">
            <span>请使用微信扫一扫至线上商店</span>
            <img src={QRCodeChina} alt="QR Code" className="qr-code" />
            <span className="help-text -mainland-china-text">*优惠仅限于结账时使用于部分产品。 大多数国家的优惠不包括婴儿配方奶粉和医疗食品</span>
            <span className="help-text -other-countries">
              *优惠仅限于结账时使用于部分产品。 大多数国家的优惠不包括婴儿配方奶粉和医疗食品
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default ShopNowTouchscreen

function toggleShopNow(e) {
  document.querySelector('.shop-now-toggle.-touchscreen').classList.toggle('-open')
  document.body.classList.toggle('-shop-now-active')
}

function updateCta(event) {
  let el = event.target
  
  if (el.getAttribute('id') === 'destChinaTouchscreen') {
    document.querySelector('.qr-code-wrapper span').innerHTML = '请使用微信扫一扫至线上商店'

    var chinaText = document.querySelectorAll('.help-text.-mainland-china-text'), i;

    for (i = 0; i < chinaText.length; ++i) {
      chinaText[i].style.display = "block";
    }

    var otherText = document.querySelectorAll('.help-text.-other-countries'), j;

    for (j = 0; j < otherText.length; ++j) {
      otherText[j].style.display = "none";
    }
  } else {
    document.querySelector('.qr-code-wrapper span').innerHTML = '扫描二维码'

    var chinaTextTwo = document.querySelectorAll('.help-text.-mainland-china-text'), k;

    for (k = 0; k < chinaTextTwo.length; ++k) {
      chinaTextTwo[k].style.display = "none";
    }

    var otherTextTwo = document.querySelectorAll('.help-text.-other-countries'), l;

    for (l = 0; l < otherTextTwo.length; ++l) {
      otherTextTwo[l].style.display = "block";
    }
  }
  document.querySelector('.qr-code').setAttribute('src', el.getAttribute('value'))

  document.querySelector('.qr-code-wrapper').style.display = 'block'
}