import React from "react"
import { Link } from "gatsby"
 
class ChineseCookieModal extends React.Component {
  componentDidMount() {
    this.cookieCheck()
  }

  cookieCheck () {
    // If no 'accept-cookies-chinese' cookie found, show cookie bar

    if (document.cookie.match('accept-cookies-chinese') == null) {
      if (window.innerWidth > 900) {
        document.cookie = 'accept-cookies-chinese=true'
        document.getElementsByClassName('js-cookie-consent')[0].classList.remove('-modal-active')
        document.body.classList.remove('-modal-active')
      } else {
        document.querySelector('.js-cookie-consent').classList.add('-modal-active')
        setTimeout(function() {
          document.body.classList.add('-modal-active')
        },100);
      }
    } else {
      setTimeout(function() {
        document.body.classList.remove('-modal-active')
      },100);
    }
  }

  render() {
    return(
      <div className="modal js-cookie-consent">
        <span className="modal__close js-cookie-accept" onClick={cookieClose}><i className="fas fa-times"></i></span>
        
        <div className="modal-inner">
          <div className="modal-inner-content">
            <span className="modal-inner-content__title">使用cookie</span>
            <p>我们可以使用一种您的浏览器支持、称为“cookie”的技术将某些信息储存在您的计算机上。cookie用途多样，包括但不限于：帮助您登录（如在您注册或登录时记录您的用户名）；记录您的某些定制用户偏好；帮助我们更好地理解人们如何使用我们的网站；以及帮助我们改善网站。有时候，这些信息可以帮助我们为您量身定制您感兴趣的内容，或使我们无需在您再次访问网站时询问相同的信息。我们网站设定的cookies仅为我们网站之用。如需停用或限制cookie的使用，请参阅您的互联网浏览器所提供的选项。但是，如果您这样做的话，本网站的某些页面可能会无法正常工作。</p>
            <Link to="/cookies-policy" className="cta -gold-gradient js-cookie-accept" onClick={cookieClose}>接受cookie</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default ChineseCookieModal

function cookieClose(e) {
  e.preventDefault()
  document.cookie = 'accept-cookies-chinese=true'
  document.getElementsByClassName('js-cookie-consent')[0].classList.remove('-modal-active')
  document.body.classList.remove('-modal-active')
}