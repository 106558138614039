import React from "react"
import { Helmet } from "react-helmet"
import ChineseHeader from "./ChineseHeader"
import ChineseShopNow from "./ChineseShopNow"
import ChineseShopNowTouchscreen from "./ChineseShopNowTouchscreen"
import ChineseCookieModal from "./ChineseCookieModal"
import ChineseNutritionModal from "./ChineseNutritionPledgeModal"
import MobileOptimised from "./MobileOptimised"
import ChineseFooter from "./ChineseFooter"

export default ({ children }) => {
  return (
    <div>
      <Helmet>
        <html xmlns="http://www.w3.org/1999/xhtml" lang="zh" />
        <meta charSet="utf-8" />
        <title>Meet MJN</title>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <body className="zh" />
      </Helmet>
      <ChineseHeader />
      {children}
      <ChineseFooter />
      <ChineseShopNow />
      <ChineseShopNowTouchscreen />
      <ChineseCookieModal />
      <ChineseNutritionModal />
      <MobileOptimised />
    </div>
  )
}