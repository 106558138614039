import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import ChineseLayout from "../../components/ChineseLayout"
// import Inactivity from "../../components/Inactivity"

/* Basic content page structure */

class PrivacyNotice extends React.Component {
  componentDidMount() {
    // Inactivity()
  }

  render() {
    return (
      <ChineseLayout>
        <Helmet>
          <title>Meet MJN - 隐私声明</title>
        </Helmet>
        <main role="main" className="content-main">

          <section className="hero-area -no-content">

          </section>

          <section className="rich-text -bg-white">
            <nav className="breadcrumb" role="navigation">
              <Link to="/zh" title="首页">
                <i className="fas fa-arrow-left"></i>
                首页
              </Link>
            </nav>
            <h1>隐私声明</h1>
            
            <h3>隐私声明的目的</h3>

            <p>        美赞臣营养品（中国）有限公司尊重本网站浏览者的隐私，并明白有关您、您健康以及保健的信息为敏感信息。除了本网站“互联网隐私声明”的“控制转让”部分规定的以外，美赞臣营养品（中国）有限公司不会出售或出租您的个人识别信息。</p>

            <p>        美赞臣营养品（中国）有限公司编写此“互联网隐私声明”是为了提供我们对您在访问本网站时所提供信息的收集、使用和披露的惯例。</p>

            <p>        我们建议您在使用本网站或提交信息前阅读“互联网隐私声明”。使用本网站表示您已确认理解并同意“互联网隐私声明”的条款。</p>

            <h3>用户自愿提供的信息</h3>

            <p>       您可访问大部分的网站而无需告诉我们您是谁或向我们提供其它个人识别信息。网站的某些栏目允许您联系我们以提出问题或提供意见。您与我们通信并要求回应时，我们会询问您的名字以及联系信息。我们会在从您处收集信息前告诉您将如何使用这些信息，如果您说明不希望使用这些信息作满足您要求所需以外的进一步联系，我们会尊重您的想法。</p>
            
            <p>        本网站的某些栏目也可能要求您提交信息以访问某些特定的内容、特性、互动工具、服务或参与特定活动。例如，创建网上帐户或订阅简报时，我们可能会询问您的名字、联系信息或其它额外信息以便能够对您的请求作出最贴切的回应。</p>

            <p>        此外，当您使用某些互动工具或服务时，可能会要求您提供个人识别信息。自始至终您都可以选择不提供所需的信息，但是这样的话，您就无法参与某些需要这些信息的特定活动。</p>

            <h3>使用和披露您自愿提供的个人识别信息</h3>
            
            <p>        我们可能需要为某些浏览者个性化和定制本网站。</p>

            <p>        如果您通过联系表提供了您的信息，可能会发现我们已根据您之前与网站的互动以及所提供的信息对网站进行了产品和/或服务偏好上的定制，我们相信您对这些产品/服务会感到兴趣。</p>

            <p>        您确实向我们提供了个人识别信息，如名字、通讯地址、电子邮箱地址、电话号码、宝宝姓名、宝宝生日等信息，除非您按如下所述表明您决定不参与该服务，否则表示您同意我们通过邮寄、电话或电子邮件等各种方式为您提供我们相信您会感兴趣的信息。</p>

            <p>        如果您决定参与美赞臣营养品（中国）有限公司的活动，我们可能会与其它MeadJohnson公司共享您的信息，这些公司可能会邀请您参与它们的某个活动。</p>

            <p>        如果您未决定不参与该服务，我们也可能会与帮助美赞臣营养品（中国）有限公司开发活动并为美赞臣营养品（中国）有限公司提供服务的服务提供商共享这些信息，并为您提供您可能会感兴趣的服务，或将这些信息用于信息处理、邮寄和/或基于互联网的交付用途。</p>

            <p>        除了“控制转让”部分规定的以外，美赞臣营养品（中国）有限公司不会出售或出租您的个人识别信息。此外，如“控制转让”部分所述，您的个人识别信息可能会在未获得您同意或通知您的情况下，与我们改组或出售所有或部分业务或某个业务系列的预期交易实际或潜在方共享。在这种情况下，我们将会寻求书面保证，确保通过本网站提交的个人识别信息能够受到适当保护。</p>

            <h3>使用和披露非个人识别信息</h3>

            <p>        美赞臣营养品（中国）有限公司不会对任何您提供的非个人识别信息进行保密，如问题、评论、想法或建议等。请您注意，美赞臣营养品（中国）有限公司可通过任何方式自由披露此类信息或将其用于任何用途。</p>

            <p>        为美赞臣营养品（中国）有限公司提供此类信息表示，您理解并同意美赞臣营养品（中国）有限公司与您之间并未建立任何关系，并且美赞臣营养品（中国）有限公司对您在这些信息上不承担任何义务。</p>

            <h3>更新和修正个人识别信息</h3>

            <p>        您可以联系我们更新或修正通过本网站提供的大部分个人识别信息（下文“规章要求”部分所述的受限信息除外），或取消邮寄以及其它您已要求提供的服务。为此，您可以直接登录美赞臣中国网站<a href="www.meadjohnson.com.cn" target="_blank" rel="noopener noreferrer">www.meadjohnson.com.cn</a>管理您的帐户信息，或者拨打电话020-38111188，或者邮寄至“广州市天河区林和中路8号天誉大厦28楼美赞臣营养品（中国）有限公司”联系我们。</p>

            <h3>安全</h3>

            <p>        我们会采取合理的预防措施以保护通过本网站收集、按“互联网隐私声明”使用的个人识别信息的安全。除技术保护之外，我们同时也使用物理控制和程序保护您的个人识别信息。但是，我们仍然无法保证这些信息对那些试图避开我们的安全措施或截取互联网传输的人来说是完全安全的。</p>

            <h3>使用cookie</h3>

            <p>        我们可以使用一种您的浏览器支持、称为“cookie”的技术将某些信息储存在您的计算机上。cookie用途多样，包括但不限于：帮助您登录（如在您注册或登录时记录您的用户名）；记录您的某些定制用户偏好；帮助我们更好地理解人们如何使用我们的网站；以及帮助我们改善网站。有时候，这些信息可以帮助我们为您量身定制您感兴趣的内容，或使我们无需在您再次访问网站时询问相同的信息。我们网站设定的cookies仅为我们网站之用。如需停用或限制cookie的使用，请参阅您的互联网浏览器所提供的选项。但是，如果您这样做的话，本网站的某些页面可能会无法正常工作。</p>

            <h3>Webbeacon</h3>

            <p>        Webbeacon是一个清晰的GIF（图形交换格式）图像或像素标记，它由公司置于其网站之上，可帮助互联网广告或观众测量公司分析其广告活动以及浏览者对其网站的一般使用模式。美赞臣营养品（中国）有限公司在其某些网站上使用Webbeacon。除了下文“控制转让”部分规定的以外，美赞臣营养品（中国）有限公司不会出售或出租您的个人识别信息。</p>

            <h3>网络服务器日志和IP地址</h3>

            <p>        不管有否cookie，我们的网站都会跟踪使用数据，如页面请求的来源地址（即您的IP地址，域名）、页面请求的日期和时间、来源网站（如有）以及URL中的其它参数（如搜索准则）。我们使用这些数据以便能够更好地理解网站的整体使用情况，并确定用户偏爱我们网站中的哪些栏目（如根据这些栏目的浏览次数）。这些信息储存在日志文件中，并由美赞臣营养品（中国）有限公司用作统计报告之用。</p>

            <h3>调查及比赛</h3>

            <p>        我们可能会邀请您参与相关调查或比赛活动，并为此要求您提供有关可辨识您个人身份的资料和信息。在此情況下，我们会向您清楚说明收集这些资料、信息的目的及用途。</p>

            <h3>点击率数据</h3>

            <p>         「点击率」或「点击率数据」反映某网页被用戶访问的频率，以及用戶由一个网页跳入另一网页的情況或模式。我们只会追踪用戶在本网站內的「点击率」或「点击率数据」等资料，而不会特別追踪美赞臣营养品（中国）有限公司网页以外的其他任何点击率。</p>

            <h3>控制转让</h3>
            
            <p>        可能会出现这样的情况，即我们决定通过销售、过户、合并、收购或其它转让方式改组或出售所有或部分业务或某个业务系列，包括我们的信息数据库和网站。在上述任何情况下，我们可能会自行或授权别人将个人识别信息与预期交易或有关的实际或潜在方共享（无需获得您的同意或另行通知您）。在这种情况下，我们将会寻求书面保证，确保通过本网站提交的个人识别信息能够受到适当保护。</p>

            <h3>规章要求</h3>

            <p>        如果您联系我们，向我们提供有关使用我们产品的体验，则我们可能会在提交到美国食品及药物管理局的报告中使用您所提供的信息，以及在法律或政府审计要求我们提供时使用此类信息。我们也可能使用这些信息与您的保健专业人员联系，以跟进涉及到使用我们任何产品的意外事件。您应能够理解，为遵守法律之所需，美赞臣营养品（中国）有限公司可能无法满足您修改或移除由保健专业人员或消费者向美赞臣营养品（中国）有限公司提供的涉及药品、医疗产品或医疗设备的不良事件或反应的个人识别信息。</p>

            <h3>法律执行</h3>

            <p>        在极少数的情况下，法院指令、其它法律或规章程序可能会要求我们向执法机构、法院或规章制定机构披露个人识别信息。对于这些要求，美赞臣营养品（中国）有限公司将会按照规章或法律程序给予合作，并采取适当的措施确保要求方能够理解其所接收的任何与健康有关的个人识别信息的敏感性质。</p>

            <h3>约束链接网站的隐私政策</h3>
            
            <p>        为浏览者方便起见，美赞臣营养品（中国）有限公司的网站包含了到其它可能提供有用信息的网站的链接。此处所述的“互联网隐私声明”不适用于您使用的此类其它网站。使用链接网站前，请查阅其互联网隐私声明以获知其如何使用和保护个人识别信息。</p>

            <h3>隐私声明的改变</h3>

            <p>        美赞臣营养品（中国）有限公司可能会随时通过更新本告示来修改本“互联网隐私声明”。这些修订会对您有所影响，因此，应定期浏览本页面以查阅最新的隐私声明。</p>

            <h3>儿童网上隐私</h3>

            <p>        我们致力于保护儿童的隐私。我们不会收集18岁以下儿童的个人识别信息。如果某个儿童向我们提供了个人识别信息，其父母或监护人可与我们联系。联系电话020-38111188，邮寄地址“广州市天河区林和中路8号天誉大厦28楼美赞臣营养品（中国）有限公司”。</p>          

            <h3>问题</h3>

            <p>        如果您有任何关于美赞臣营养品（中国）有限公司网站“互联网隐私声明”或我们网站上关于隐私的其它方面的问题，请与我们联系。联系电话020-38111188，邮寄地址“广州市天河区林和中路8号天誉大厦28楼美赞臣营养品（中国）有限公司”。</p>          

          </section>
        </main>
      </ChineseLayout>
    )
  }
}

export default PrivacyNotice