import React from "react"
import { Link } from "gatsby"
import PopupImage from "../images/static-only/breast-is-best-popup.jpg"
 
class ChineseNutritionPledgeModal extends React.Component {
  componentDidMount() {
    this.modalCheck()
  }

  modalCheck () {
    if ((document.cookie.match('accept-modal-chinese') == null) && (document.cookie.match('accept-cookies-chinese') != null)) {
      document.querySelector('.js-modal').classList.add('-modal-active')
      setTimeout(function() {
        document.body.classList.add('-modal-active')       
      },200);
    }
  }

  render() {
    return(
      <div className="modal js-modal">
        <span className="modal__close js-modal-close" onClick={closeModal}><i className="fas fa-times"></i></span>
        
        <div className="modal-inner">
          <img src={PopupImage} alt="Mother breast feeding her baby" className="modal-inner__image" />

          <div className="modal-inner-content">
            <span className="modal-inner-content__title">我们的营养承诺</span>
            <p>我们认识到，在怀孕的前1000天内（从受孕到两岁的这段时期）合理的营养对孩子的学习和成长能力具有至关重要的影响，<strong>并为其大脑发育、健康成长和发展强大的免疫系统提供了重要的基础。</strong>因此，我们支持并促进世界卫生组织（世卫组织）在宝宝的前六个月内进行纯母乳喂养的建议，并在其后引入安全，适合年龄和营养的辅助食品。 此外，我们主张持续母乳喂养直至两岁及以上。对于无法或不选择母乳喂养的母亲，我们的职责是提供最优质的产品，以帮助满足母亲，婴儿和儿童的营养需求。</p>
            <Link to="/zh/our-nutrition-pledge" className="cta -gold-gradient js-modal-cta" onClick={modalCta}>阅读更多</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default ChineseNutritionPledgeModal

function closeModal() {
  document.cookie = 'accept-modal-chinese=true'
  var modal = document.querySelector('.js-modal')
  modal.classList.remove('-modal-active')
  modal.addEventListener('transitionend', function() {
    document.body.classList.remove('-modal-active')
  })
}

function modalCta(e) {
  e.preventDefault()
  
  document.cookie = 'accept-modal-chinese=true'

  window.location.href = e.target.href
}